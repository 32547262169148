//
// Use this to write your custom SCSS
//
* {
  letter-spacing: -.36px;
}
.app {
  &-btn {
    &-black {
      @include button-outline-variant($white, $black, $white, $black, $black);
      background: $black;
      border-color: $black;
      &:disabled,
      &.disabled {
        color: $white;
        background-color: $secondary-dis;
        border-color: $secondary-dis;
      }
    }

    &-yellow {
      @include button-outline-variant($black, $white, $black, $black, $white);
      background: $primary-yellow;
    }

    &-white {
      @include button-outline-variant($black, $white, $black, $black, $white);
      background: $white;
    }

    &-gray {
      @include button-outline-variant($black, $black, #ececec, #ececec, $black);
      background: $white;
    }

    &-gray-second {
      @include button-outline-variant($light-second, $black, $white, $black, $black);
      background: $light-second;
    }

    &-green {
      @include button-outline-variant($black, $white, $black, $black, $white);
      background: $primary-salad;
    }
  }

  &-bg {
    &-yellow:not(.active) {
      background-color: $primary-yellow !important;
    }

    &-salad {
      background-color: $primary-salad !important;
    }
  }
}

.pulse {
  animation: pulse-animation 3s infinite;
  animation-delay: 5s;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 $primary-yellow;
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

.modal.fade .modal-dialog {
  transform: translate(0px, 0px)!important;
}

.carousel-indicators [data-bs-target] {
  border-radius: 50%;
}

.vip-modal {
  padding: 10px;

  & .modal-content {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      background: url("../icons/vip_modal_label.svg");
      width: 93px;
      height: 93px;
      left: -11px;
      top: -11px;
    }
  }
}

router-outlet {
  & + * {
    router-outlet {
      & + * {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        flex: 1;
        overflow-y: hidden;
      }
    }
  }
}

select {
  cursor: pointer;

  &:invalid {
    color: $secondary-dis;
  }
}
